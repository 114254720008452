<template>
  <div
    class="gamelist-col"
    :class="name"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'gamelist-col',

  props: {
    name: {
      type: [ String ]
    }
  }
}
</script>

<style lang="css" scoped>
</style>
